import React from "react";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <div id="section1">
      <video src="https://web3deploy.com/videos/vid1.mp4" id="s1vid" autoPlay loop muted></video>
      <div className="s1inner container-fluid">
        <div>
          <div className="intro">CROWNMANIA</div>
          <div className="intro2">
            YOUR ALL IN ONE DESTINATION FOR COLLECTIBLES FASHION AND MORE!
          </div>
          <br />
          <Link to="/products" className="btn btnbgwhite wallet3">
            SHOP NOW
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Banner;
